import { useCallback } from "react";

const AVAILABLE_LANGUAGES = ["en", "fr"];

function useBrowserLanguage() {
  const browserLanguage =
    navigator.languages
      .map((lang) => lang.split("-")[0])
      .find((lang) => AVAILABLE_LANGUAGES.includes(lang)) || "en";

  const getTextInBrowserLanguage = useCallback(
    (textDict: { [key: string]: string }) => textDict[browserLanguage],
    [browserLanguage]
  );

  return { getTextInBrowserLanguage, browserLanguage };
}

export default useBrowserLanguage;
