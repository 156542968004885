import React from "react";
import { AlertNotification as AlertNotificationModel } from "../../models/notifications";

import { OperatorEnum, RuleTypeEnum } from "../../models/alerts";
import useBrowserLanguage from "../../hooks/useBrowserLanguage";

const AlertNotification: React.FC<{
  notification: AlertNotificationModel;
}> = ({ notification }) => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();

  const human_readable_operator = (operator: OperatorEnum) => {
    if (operator === "gt") {
      return getTextInBrowserLanguage({
        en: "greater than",
        fr: "supérieur à",
      });
    } else if (operator === "gte") {
      return getTextInBrowserLanguage({
        en: "greater than or equal to",
        fr: "supérieur ou égal à",
      });
    } else if (operator === "lt") {
      return getTextInBrowserLanguage({
        en: "less than",
        fr: "inférieur à",
      });
    } else if (operator === "lte") {
      return getTextInBrowserLanguage({
        en: "less than or equal to",
        fr: "inférieur ou égal à",
      });
    } else if (operator === "eq") {
      return getTextInBrowserLanguage({
        en: "equal to",
        fr: "égal à",
      });
    }

    return "";
  };

  const get_notification_message = () => {
    if (notification.alert_detail.type === RuleTypeEnum.main_score) {
      return getTextInBrowserLanguage({
        en: `${
          notification.campaign_name
        }: The global score is ${human_readable_operator(
          notification.alert_detail.operator
        )} ${notification.alert_detail.score}.`,
        fr: `${
          notification.campaign_name
        }: Le score global est ${human_readable_operator(
          notification.alert_detail.operator
        )} ${notification.alert_detail.score}.`,
      });
    } else if (notification.alert_detail.type === RuleTypeEnum.section_score) {
      return getTextInBrowserLanguage({
        en: `${notification.campaign_name}: The score of the section '${
          notification.alert_detail.section_name
        }' is ${human_readable_operator(notification.alert_detail.operator)} ${
          notification.alert_detail.score
        }.`,
        fr: `${notification.campaign_name}: Le score de la section '${
          notification.alert_detail.section_name
        }' est ${human_readable_operator(notification.alert_detail.operator)} ${
          notification.alert_detail.score
        }.`,
      });
    } else if (
      notification.alert_detail.type === RuleTypeEnum.additional_score
    ) {
      return getTextInBrowserLanguage({
        en: `${notification.campaign_name}: The additional score '${
          notification.alert_detail.score_name
        }' is ${human_readable_operator(notification.alert_detail.operator)} ${
          notification.alert_detail.score
        }.`,
        fr: `${notification.campaign_name}: Le score additionnel '${
          notification.alert_detail.score_name
        }' est ${human_readable_operator(notification.alert_detail.operator)} ${
          notification.alert_detail.score
        }.`,
      });
    } else if (
      notification.alert_detail.type === RuleTypeEnum.binary_item ||
      notification.alert_detail.type === RuleTypeEnum.multiple_levels_item ||
      notification.alert_detail.type === RuleTypeEnum.composed_item
    ) {
      return getTextInBrowserLanguage({
        en: `${notification.campaign_name}: The item '${notification.alert_detail.item_name}' is not compliant.`,
        fr: `${notification.campaign_name}: L'item '${notification.alert_detail.item_name}' n'est pas conforme.`,
      });
    }

    return "";
  };

  return <div>{get_notification_message()}</div>;
};

export default AlertNotification;
